import { Typography, Progress, Row, Col } from "antd";
import "./App.less";

export default function Skill(props: any) {
  return (
    <Row
      style={{ width: "100%", minHeight: "100vh", margin: "auto" }}
      align="middle"
      justify="center"
    >
      <Col
        xs={24} sm={24} md={16}
        style={{
          padding: 24,
        }}
      >
        <Typography.Title level={1} style={{ padding: 8 }}>
          Skills
        </Typography.Title>
        <Col>
          {[
            ["Python", 100],
            ["C/C++", 65],
            ["Visual Basic", 45],
            ["C#", 55],
            ["Java", 35],
            ["HTML/CSS", 100],
            ["PHP", 100],
            ["SQL", 100],
            ["Vanilla JS", 100],
            ["jQuery", 100],
            ["Typescript", 65],
            ["React", 20],
            ["Unity", 25],
            ["R", 35],
            ["MATLAB", 10],
            ["Lua", 15],
          ].map((d) => {
            let status = "";
            if (Number(d[1]) > 60) status = "#50A3A4";
            if (Number(d[1]) <= 60) status = "#FCAF38";
            if (Number(d[1]) <= 40) status = "#F95335";

            return (
              <Row
                gutter={0}
                align="middle"
                style={{ width: "90%", margin: "auto", padding: 18 }}
              >
                <Col xs={24} md={4} sm={24}>
                  <Typography.Text>{d[0]}</Typography.Text>
                </Col>
                <Col xs={24} sm={24} md={20} style={{ margin: "auto" }}>
                  <Progress
                    trailColor="#444"
                    strokeLinecap="round"
                    strokeColor={status}
                    percent={Number(d[1])}
                  />
                </Col>
              </Row>
            );
          })}
        </Col>
      </Col>
    </Row>
  );
}
