/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from "react";
import { Layout, Menu, BackTop, Typography } from "antd";
import {
  BookOutlined,
  InfoCircleOutlined,
  ProjectOutlined,
  FlagOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import "./App.less";
import About from "./About";
import Skills from "./Skill";
import Project from "./Projects";
import Portfolio from "./Portfolio";
import Contact from "./Contact";
const { Content, Sider, Footer } = Layout;

const App: FC = () => {
  const [isCollapsed, setCollapsed] = useState(true);
  return (
    <Layout>
      <BackTop />
      <Sider
        collapsed={isCollapsed}
        collapsible
        trigger={null}
        defaultCollapsed
        style={{
          minHeight: "100vh",
          position: "fixed",
          overflowX: "hidden",
          zIndex: 2,
          textAlign: "center",
        }}
      >
        <Typography.Title
          style={{
            textAlign: "center",
            margin: "auto",
            padding: 20,
            cursor: "pointer",
          }}
          onClick={() => setCollapsed(!isCollapsed)}
          underline
        >
          T
        </Typography.Title>

        <Menu
          theme="dark"
          mode="vertical"
          selectable={false}
          style={{ height: "100%", margin: "auto" }}
        >
          {[
            ["About", "#about", <InfoCircleOutlined />],
            ["Skills", "#skills", <FlagOutlined />],
            ["Portfolio", "#portfolio", <BookOutlined />],
            ["Project", "#project", <ProjectOutlined />],
            ["Contact", "#contact", <ContactsOutlined />],
          ].map((values, index) => {
            const key = index + 1;
            return (
              <Menu.Item
                icon={values[2]}
                key={key}
                onClick={() => {
                  window.location.assign(String(values[1]));
                  setCollapsed(true);
                }}
                style={{ marginTop: 40 }}
              >
                <Typography.Text style={{ verticalAlign: "middle" }}>
                  {values[0]}
                </Typography.Text>
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
      <Layout style={{ marginLeft: 80 }}>
        <Content>
          <div className="site-layout-content">
            <a id="about" title="About" />
            <About />
            <a id="skills" title="Skills" />
            <Skills />
            <a id="portfolio" title="Portfolio" />
            <Portfolio />
            <a id="project" title="Project" />
            <Project />
            <a id="contact" title="Contact" />
            <Contact />
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          &copy; 2023 TianTcl
        </Footer>
      </Layout>
    </Layout>
  );
};

export default App;
