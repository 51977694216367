import { Typography, Space, Col, Row, Tag } from "antd";
import "./App.less";
import PortDrawer from "./assets/drawer";

export default function Portfolio(props: any) {
	return (
		<Row style={{ width: "100%", minHeight: "100vh" }} align="middle">
			<Col span={24}>
				<Typography.Title style={{ marginTop: 24 }}>Portfolio</Typography.Title>
				<Typography.Title level={5} style={{ marginTop: -15 }} type="warning">Click to see more..</Typography.Title>
				<Typography.Title level={4} style={{ color: "cyan", marginTop: 12 }}>Academic year 2015</Typography.Title>
				<Space size={16} wrap style={{ justifyContent: "center" }}>
					<PortDrawer
						title="กัลปพฤกษ์เกมส์"
						description={
							<Typography.Text>
								Bronze medal, Chess Team competition
							</Typography.Text>
						}
						org="Demonstration affiliated schools"
						tags={
							<>
								<Tag color="red">Sport / Games</Tag>
							</>
						}
						link={"http://rs.kpg40.kku.ac.th/reward/rewardpthdetail.php?school=12#:~:text=ด.ช.เด็กชายชัยณัฎฐ์ ลิ้มชุณหนุกูล"}
					>
						<Typography.Text>
							กีฬาสาธิตสามัคคี ครั้งที่ 40 หรือ กัลปพฤกษ์เกมส์ hosted by โรงเรียนสาธิตมหาวิทยาลัยขอนแก่น in that year. I won a bronze medal for I practiced hard on chess and concentrated well in the competition.
						</Typography.Text>
					</PortDrawer>
				</Space>
				<Typography.Title level={4} style={{ color: "cyan", marginTop: 24 }}>Academic year 2016</Typography.Title>
				<Space size={16} wrap style={{ justifyContent: "center" }}>
					<PortDrawer
						title="คณะกรรมการนักเรียน"
						description={
							<Typography.Text>
								การจำลองระบบประชาธิปไตยในโรงเรียน
							</Typography.Text>
						}
						org="Ramkamhaeng University Demonstration School (Elementary Level)"
						tags={
							<>
								<Tag color="orange">Poll / Votes</Tag>
							</>
						}
						link={"http://www.kids.ru.ac.th/images/srisatit/2559_21.asp"}
					>
						<Typography.Text>
							I prepared and made an election campaign well for our team got the first place in the school's student president election.
						</Typography.Text>
					</PortDrawer>
				</Space>
				<Typography.Title level={4} style={{ color: "cyan", marginTop: 24 }}>Academic year 2017</Typography.Title>
				<Space size={16} wrap style={{ justifyContent: "center" }}>
					<PortDrawer
						title="ประธานนักเรียน"
						description={
							<Typography.Text>
								การจำลองระบบประชาธิปไตยในโรงเรียน
							</Typography.Text>
						}
						org="Ramkamhaeng University Demonstration School (Elementary Level)"
						tags={
							<>
								<Tag color="orange">Poll / Votes</Tag>
							</>
						}
						link={"https://facebook.com/photo.php?fbid=10209609075529001&set=t.100007019323990&type=3"}
					>
						<Typography.Text>
							I prepared and made an election campaign well for my team got the first place in the school's student president election. I am a schooll president of that academic year.
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="รางวัลเด็กและเยาวชนดีเด่น"
						description={
							<Typography.Text>
								รางวัลเด็กและเยาวชนดีเด่น ประจำปี พ.ศ. 2561 จากกระทรวงศึกษาธิการ
							</Typography.Text>
						}
						org="Ministry of Education, Thailand"
						tags={
							<>
								<Tag color="green">Certify</Tag>
							</>
						}
						link={"https://facebook.com/jern.anuworakarn/posts/10210967359085241"}
					>
						<Typography.Text>
							Visit RU's PR official post <a href="https://facebook.com/ramkhamhaeng.ru/posts/1620841051272584" target="_blank" rel="noreferrer">[here]</a>
						</Typography.Text>
					</PortDrawer>
				</Space>
				<Typography.Title level={4} style={{ color: "cyan", marginTop: 24 }}>Academic year 2018</Typography.Title>
				<Space size={16} wrap style={{ justifyContent: "center" }}>
					<PortDrawer
						title="บดินทรเดชาวิชาการ"
						description={
							<Typography.Text>
								รางวัลชนะเลิศการนำเสนอโครงงานด้วยโปสเตอร์
							</Typography.Text>
						}
						org="Bodindecha (Sing Singhaseni) School"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="blue">Presentation</Tag>
								<Tag color="gray">Project</Tag>
							</>
						}
						link={"https://facebook.com/jern.anuworakarn/posts/10213726169933788"}
					>
						<Typography.Text>
							กิจกรรมประกวดโครงงาน Project Based Learning. กลุ่มเราได้ทำโครงงาน "การเพาะเห็ดนางฟ้าภูฐาน" เป็นโครงงานสาขาเศรษฐกิจพอเพียง. ได้รับรางวัลชนะเลิศการนำเสนอโครงงานด้วยโปสเตอร์.
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="บดินทรเดชาวิชาการ"
						description={
							<Typography.Text>
								Gold medal 2<sup>nd</sup> runner up
							</Typography.Text>
						}
						org="Bodindecha (Sing Singhaseni) School"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="gray">Project</Tag>
							</>
						}
					>
						<Typography.Text>
							การประกวดโครงงานสาขาปรัชญาเศรษฐกิจพอเพียงระกับชั้นมัธยมศึกษาตอนต้นโรงเรียนบดินทรเดชา (สิงห์ สิงหเสนี)
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="การสอบแช่งขันคณิตศาสตร์สพฐ."
						description={
							<Typography.Text>
								Bronze award
							</Typography.Text>
						}
						org="สพม.เขต 2 กรุงเทพมหานคร"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="magenta">Examination</Tag>
							</>
						}
					>
						<Typography.Text>
							การสอบแข่งขันวิชาคณิตศาสตร์ ระดับมัธยมศึกษาตอนต้น โดย สพฐ.
						</Typography.Text>
					</PortDrawer>
				</Space>
				<Typography.Title level={4} style={{ color: "cyan", marginTop: 24 }}>Academic year 2019</Typography.Title>
				<Space size={16} wrap style={{ justifyContent: "center" }}>
					<PortDrawer
						title="ศิลปหัตถกรรม"
						description={
							<Typography.Text>
								2 Golden medal, 1 silver medal
							</Typography.Text>
						}
						org="สพม.เขต 2 กรุงเทพมหานคร"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="gray">Project</Tag>
							</>
						}
					>
						<Typography.Text>
							ร่วมแข่งขันงานศิลปหัตถกรรมนักเรียน ครั้งที่ 69 ระดับมัธยมศึกษาตอนต้น ระดับเขตพื้นที่การศึกษา<ol>
								<li>รางวัล<b>เหรียญทอง</b>การประกวด<b>โครงงานคอมพิวเตอร์</b>ประเภทซอฟต์แวร์ <a href="https://facebook.com/jern.anuworakarn/posts/10215447479925462" target="_blank" rel="noreferrer">[view]</a></li>
								<li>รางวัล<b>เหรียญทอง</b>การแข่งขันสร้างสรรค์ผลงานคณิตศาสตร์โดยใช้โปรแกรม <b>GSP</b></li>
								<li>รางวัล<b>เหรียญเงิน</b>การแข่งขัน<b>การสร้าง Webpage</b> ประเภท Web Editor <a href="https://facebook.com/jern.anuworakarn/posts/10215463572607769" target="_blank" rel="noreferrer">[view]</a></li>
							</ol>
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="Thai IoT International Conference &amp; Pitching"
						description={
							<Typography.Text>
								Reached Final Pitching state
							</Typography.Text>
						}
						org="Sripatum University"
						tags={
							<>
								<Tag color="teal">Pitching</Tag>
							</>
						}
						link={"https://www.spu.ac.th/activities/24177"}
					>
						<Typography.Text>
							ผ่านการคัดเลือกให้นำเสนอและตอบข้อซักถามเป็นภาษาอังกฤษในงาน Thai IoT International Conference &amp; Pitching
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="๓ ทศวรรษ น้ำพระทัยสมเด็จเจ้าฟ้า สู่โอลิมปิกวิชาการ"
						description={
							<Typography.Text>
								Silver award certificate
							</Typography.Text>
						}
						org="สอวน. สสวท. สพฐ."
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="magenta">Examination</Tag>
								<Tag color="green">Certify</Tag>
							</>
						}
						link={"https://facebook.com/jern.anuworakarn/posts/10215437389113198"}
					>
						<Typography.Text>
							จัดขึ้นที่ศูนย์นิทรรศการและการประชุมไบเทค บางนา ระหว่างวันที่ 25-26 ตุลาคม พ.ศ. 2562
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="KU01 - 2019"
						description={
							<Typography.Text>
								Algorithm problem solving challenge
							</Typography.Text>
						}
						org="Department of Computer Engineering Kasetsart University"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="purple">Coding</Tag>
							</>
						}
						link={"https://ku01.org"}
					>
						<Typography.Text>
							ผ่านการทดสอบความสามารถในการแก้ปัญหาโดยการเขียนโปรแกรม โดยคณะวิศวกรรมศาสตร์ มหาลัยเกษตรศาสตร์ (<a href="https://ku01.org" target="_blank" rel="noreferrer">KU01</a>)
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="AMC 2019"
						description={
							<Typography.Text>
								Merit award
							</Typography.Text>
						}
						org="Australian Mathematics Competition"
						tags={
							<>
							<Tag color="yellow">Competition</Tag>
							<Tag color="magenta">Examination</Tag>
							</>
						}
						link={"https://www.mathmissionth.com/home/competition/amc"}
					>
						<Typography.Text>
							การแข่งขันคณิตศาสตร์ออสเตรเลียปี ค.ศ. 2019 ได้รับรางวัลชมเชย
						</Typography.Text>
					</PortDrawer>
				</Space>
				<Typography.Title level={4} style={{ color: "cyan", marginTop: 24 }}>Academic year 2020</Typography.Title>
				<Space size={16} wrap style={{ justifyContent: "center" }}>
					<PortDrawer
						title="CTFAD 2020"
						description={
							<Typography.Text>
								Cyber security CTF competition
							</Typography.Text>
						}
						org="Royal Thai Air Force (RTAF)"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="purple">Computing</Tag>
							</>
						}
						link={"https://facebook.com/jern.anuworakarn/posts/10217942283934003"}
					>
						<Typography.Text>
							ผ่านเข้ารอบรองชนะเลิศการแข่งขันทักษะด้านความมั้นคงปลอดภัยไซเบอร์ ในระดับมัธยมศึกษา
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="Scitech@Bodin Computer Competition"
						description={
							<Typography.Text>
								Gold medal 1<sup>st</sup> runner up.
							</Typography.Text>
						}
						org="Science &amp; Technology department, Bodindecha (Sing Singhaseni) School"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="purple">Coding</Tag>
							</>
						}
					>
						<Typography.Text>
							การแข่งขันเขียนโปรแกรมแก้ปัญหาโจทย์อัลกอริทึมด้วยภาษาคอมพิวเตอร์
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="ISSI - 2020"
						description={
							<Typography.Text>
								Bronze award certificate (Poster)
							</Typography.Text>
						}
						org="พสวท. สสวท."
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="blue">Presentation</Tag>
								<Tag color="gray">Project</Tag>
								<Tag color="green">Certify</Tag>
							</>
						}
						link={"http://issi-dpst.ipst.ac.th"}
					>
						<Typography.Text>
							การนำเสนอโครงงานในการประชุมวิชาการระดับนานาชาติด้านวิทยาศาสตร์และนวัตกรรมสำหรับนักเรียน (ISSI) ครั้งที่ 1
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="KU01 - 2020"
						description={
							<Typography.Text>
								Algorithm problem solving challenge
							</Typography.Text>
						}
						org="Department of Computer Engineering Kasetsart University"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="purple">Coding</Tag>
							</>
						}
						link={"https://ku01.org"}
					>
						<Typography.Text>
							ผ่านการทดสอบความสามารถในการแก้ปัญหาโดยการเขียนโปรแกรม โดยคณะวิศวกรรมศาสตร์ มหาลัยเกษตรศาสตร์ (<a href="https://ku01.org" target="_blank" rel="noreferrer">KU01</a>)
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="JDA รุ่นที่ 2"
						description={
							<Typography.Text>
								ผ่านการคัดเลือกเข้าร่วมโครงการ
							</Typography.Text>
						}
						org="VISTEC DEPA &amp; สถาบันเอไอประเทศไทย"
						tags={
							<>
								<Tag color="green">Certify</Tag>
							</>
						}
					>
						<Typography.Text>
							ผ่านการคัดเลือกเข้าร่วมโครงการยุวทูตดิจิทัล รุ่นที่ 2
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="JSTP 23"
						description={
							<Typography.Text>
								Scholarship for research on special project
							</Typography.Text>
						}
						org="สวทช. (NSTDA)"
						tags={
							<>
								<Tag color="green">Certify</Tag>
								<Tag color="blue">Presentation</Tag>
								<Tag color="violet">Scholarship</Tag>
								<Tag color="gray">Project</Tag>
							</>
						}
						link={"https://nstda.or.th/jstp/"}
					>
						<Typography.Text>
							ผ่านการคัดเลือกเข้าร่วมโครงการพัฒนาอัจฉริยภาพทางวิทยาศาสตร์และเทคโนโลยีสำหรับเด็กปละเยาวชนระดับมัธยมศึกษาตอนต้น<br />(ได้รับทุนสนับสนุนเพื่การวิจัยโครงงานด้านสมองและการสั่งการ)<hr />Visit JSTP program <a href="http://jstp.org/" target="_blank" rel="noreferrer">[here]</a>
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="NSC 2021"
						description={
							<Typography.Text>
								ผ่านเข้ารอบรองชนะเลิศ
							</Typography.Text>
						}
						org="สวทช. (NSTDA)"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="gray">Project</Tag>
							</>
						}
						link={"https://facebook.com/groups/NSCThailand"}
					>
						<Typography.Text>
							การแข่งขันพัฒนาโปรแกรมคอมพิวเตอร์แห่งประเทศไทย NSC
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="สอวน. คอม 1"
						description={
							<Typography.Text>
								ผ่านเกณฑ์การประเมินตามหลักสูตร โครงการคอมพิวเตอร์โอลิมปิก สอวน. ค่ายที่ 2
							</Typography.Text>
						}
						org="โครงการโอลิมปิกวิชาการ มูลนิธิ สอวน. (POSN)"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="purple">Coding</Tag>
								<Tag color="green">Certify</Tag>
							</>
						}
						link={"https://posn.or.th"}
					>
						<Typography.Text>
							ค่ายวิชาการสำหรับการเตรียมคัดตัวเป็นผู้แทนการแข่งขันโอลิมปิกวิชาการนานาชาติ
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="สอวน. คอม 2"
						description={
							<Typography.Text>
								ผ่านการอบรมหลักสูตรโครงการคอมพิวเตอร์โอลิมปิก สอวน. ค่ายที่ 2
							</Typography.Text>
						}
						org="โครงการโอลิมปิกวิชาการ มูลนิธิ สอวน. (POSN)"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="purple">Coding</Tag>
								<Tag color="green">Certify</Tag>
							</>
						}
						link={"https://posn.or.th"}
					>
						<Typography.Text>
							ค่ายวิชาการสำหรับการเตรียมคัดตัวเป็นผู้แทนการแข่งขันโอลิมปิกวิชาการนานาชาติ
						</Typography.Text>
					</PortDrawer>
				</Space>
				<Typography.Title level={4} style={{ color: "cyan", marginTop: 24 }}>Academic year 2021</Typography.Title>
				<Space size={16} wrap style={{ justifyContent: "center" }}>
					<PortDrawer
						title="Reply Code Challenge 2021"
						description={
							<Typography.Text>
								Coding competition around the world
							</Typography.Text>
						}
						org="Reply"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="purple">Coding</Tag>
							</>
						}
						link={"https://challenges.reply.com/tamtamy/home.action"}
					>
						<Typography.Text>
							Top 300 teamsaround the world. I have to use multiple skills to solve the programming issue
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="ทุน พสวท."
						description={
							<Typography.Text>
								ได้รับทุนส่งเสริมระหว่างการศึกษาในชั้นมัธยมศึกษาตอนปลาย
							</Typography.Text>
						}
						org="สถาบันส่งสริมการสอนวิทยาศาสตร์และเทคโนโลยี สสวท. (IPST)"
						tags={
							<>
								<Tag color="magenta">Examination</Tag>
								<Tag color="violet">Scholarship</Tag>
							</>
						}
						link={"http://dpst.ipst.ac.th"}
					>
						<Typography.Text>
							ผ่านการคัดเลือกเป็นรักเรียนทุนในโครงการพัฒนาและส่งเสริมผู้มีความสามารถพิเศษทางวิทยาศาสตร์และเทคโนโลยี ระดับมัธยมศึกษาตอนปลาย<br />(ได้รับทุนสนับสนุนการศึกษา อุปกรการทดลอง และงานวิจัย)
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="ISSI - 2021"
						description={
							<Typography.Text>
								Golden award (Oral presentation)
							</Typography.Text>
						}
						org="พสวท. สสวท. ร่วมกับ คณะวิทยาศาสตร์ มหาวิทยาลัยเกษตรศาสตร์"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="blue">Presentation</Tag>
								<Tag color="gray">Project</Tag>
								<Tag color="green">Certify</Tag>
							</>
						}
						link={"http://issi-dpst.ipst.ac.th"}
					>
						<Typography.Text>
							การนำเสนอโครงงานในการประชุมวิชาการระดับนานาชาติด้านวิทยาศาสตร์และนวัตกรรมสำหรับนักเรียน (ISSI) ครั้งที่ 2
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="KU01 - 2021"
						description={
							<Typography.Text>
								Algorithm problem solving challenge
							</Typography.Text>
						}
						org="Department of Computer Engineering Kasetsart University"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="purple">Coding</Tag>
							</>
						}
						link={"https://ku01.org"}
					>
						<Typography.Text>
							ผ่านการทดสอบความสามารถในการแก้ปัญหาโดยการเขียนโปรแกรม โดยคณะวิศวกรรมศาสตร์ มหาลัยเกษตรศาสตร์ (<a href="https://ku01.org" target="_blank" rel="noreferrer">KU01</a>)
						</Typography.Text>
					</PortDrawer>
				</Space>
				<Typography.Title level={4} style={{ color: "cyan", marginTop: 24 }}>Academic year 2022</Typography.Title>
				<Space size={16} wrap style={{ justifyContent: "center" }}>
					<PortDrawer
						title="KU01 - 2022"
						description={
							<Typography.Text>
								Algorithm problem solving challenge
							</Typography.Text>
						}
						org="Department of Computer Engineering Kasetsart University"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="purple">Coding</Tag>
							</>
						}
						link={"https://ku01.org"}
					>
						<Typography.Text>
							ผ่านการทดสอบความสามารถในการแก้ปัญหาโดยการเขียนโปรแกรม โดยคณะวิศวกรรมศาสตร์ มหาลัยเกษตรศาสตร์ (<a href="https://ku01.org" target="_blank" rel="noreferrer">KU01</a>)
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="ศิลปหัตถกรรม"
						description={
							<Typography.Text>
								1 Champion, 1 Golden medal, 1 Silver medal
							</Typography.Text>
						}
						org="สพม.เขต 2 กรุงเทพมหานคร, ศธ."
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="orange">Web application</Tag>
								<Tag color="gray">Project</Tag>
							</>
						}
					>
						<Typography.Text>
							ร่วมแข่งขันงานศิลปหัตถกรรมนักเรียน ครั้งที่ 70 ระดับมัธยมศึกษาตอนต้น<ol>
								<li>รางวัล<b>เหรียญทอง ชนะเลิศ</b>การแข่งขันการสร้าง <b>Web Application</b> ระดับบเขตพื้นที่การศึกษา</li>
								<li>รางวัล<b>เหรียญทอง</b>การแข่งขันการสร้าง <b>Web Application</b> ระดับบ<b>ชาติ</b></li>
								<li>รางวัล<b>เหรียญเงิน</b>ประกวด<b>โครงงานคุณธรรม</b>ระดับเขตพื้นที่การศึกษา</li>
							</ol>
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="CCS 2022"
						description={
							<Typography.Text>
								รางวัลชมเชย
							</Typography.Text>
						}
						org="NBI - Youth CLUB เยาวชนสร้างชาติ"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="gray">Project</Tag>
							</>
						}
						link={"https://nbiyouth.co"}
					>
						<Typography.Text>
							การประกวดโครงงานนวัตกรรมเพื่อสิ่งแวดล้อม
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="Bodin Academic Expo"
						description={
							<Typography.Text>
								Golden award & Oral Presentation
							</Typography.Text>
						}
						org="Bodindecha (Sing Singhaseni) School"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="blue">Presentation</Tag>
								<Tag color="gray">Project</Tag>
							</>
						}
					>
						<Typography.Text>
							กิจกรรมประกวดโครงงาน Project Based Learning. กลุ่มเราได้ทำโครงงาน "Project Management System" เป็นโครงงานสาขาคอมพิวเตอร์. ได้รับรางวัลระดับเหรียญทอง และได้นำเสนอรูปแบบ oral.
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="สอวน. คอม 1"
						description={
							<Typography.Text>
								ผ่านเกณฑ์การประเมินตามหลักสูตร โครงการคอมพิวเตอร์โอลิมปิก สอวน. ค่ายที่ 1
							</Typography.Text>
						}
						org="โครงการโอลิมปิกวิชาการ มูลนิธิ สอวน. (POSN)"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="purple">Coding</Tag>
								<Tag color="green">Certify</Tag>
							</>
						}
						link={"https://posn.or.th"}
					>
						<Typography.Text>
							ค่ายวิชาการสำหรับการเตรียมคัดตัวเป็นผู้แทนการแข่งขันโอลิมปิกวิชาการนานาชาติ
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="สอวน. คอม 2"
						description={
							<Typography.Text>
								ผ่านเกณฑ์การประเมินตามหลักสูตร โครงการคอมพิวเตอร์โอลิมปิก สอวน. ค่ายที่ 2
							</Typography.Text>
						}
						org="โครงการโอลิมปิกวิชาการ มูลนิธิ สอวน. (POSN)"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="purple">Coding</Tag>
								<Tag color="green">Certify</Tag>
							</>
						}
						link={"https://posn.or.th"}
					>
						<Typography.Text>
							ค่ายวิชาการสำหรับการเตรียมคัดตัวเป็นผู้แทนการแข่งขันโอลิมปิกวิชาการนานาชาติ
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="Water Journey"
						description={
							<Typography.Text>
								เข้ารอบชิงชนะเลิศ
							</Typography.Text>
						}
						org="บริษัท ส.นภา ร่วมกับ จุฬาลงกรณ์มหาวิทยาลัย"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
							</>
						}
					>
						<Typography.Text>
							บริบทใหม่ของสื่อเพื่อบริหารจัดการน้ำยั่งยืน
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="กรรมการนักเรียน"
						description={
							<Typography.Text>
								การจำลองระบบประชาธิปไตยในโรงเรียน
							</Typography.Text>
						}
						org="Bodindecha (Sing Singhaseni) School"
						tags={
							<>
								<Tag color="orange">Poll / Votes</Tag>
							</>
						}
						link={"https://www.facebook.com/bodindechasc/posts/627540099384282"}
					>
						<Typography.Text>
							เป็นกิจกรรมที่ส่งเสริมให้นักเรียนมีภาวะผู้นำ ได้ร่วมกำกิจกรรมช่วยเหลืองานต่างๆ ของโรงเรียน
						</Typography.Text>
					</PortDrawer>
				</Space>
				<Typography.Title level={4} style={{ color: "cyan", marginTop: 24 }}>Academic year 2023</Typography.Title>
				<Space size={16} wrap style={{ justifyContent: "center" }}>
					<PortDrawer
						title="เยาวชนดีเด่นกรุงเทพมหานคร"
						description={
							<Typography.Text>
								รับคัดเลือกเป็นเยาวชนดีเด่น (ประกายเพชร) จากกรุงเทพมหานคร
							</Typography.Text>
						}
						org="Bangkok, Thailand"
						tags={
							<>
								<Tag color="green">Certify</Tag>
							</>
						}
						link={"https://facebook.com/jern.anuworakarn/posts/10224410427513550"}
					>
						<Typography.Text>
							Visit <a href="https://fb.me/BYCTJ" target="_blank" rel="noreferrer">BYCTJ</a> official post <a href="https://webportal.bangkok.go.th/thai_japan/page/main/5721/ประชาสัมพันธ์/0/info/341245/ประกาศผลการคัดเลือกเยาวชนดีเด่นกรุงเทพมหานคร-ประกายเพชร-ครั้งที่-18-ประจำปี-2566" target="_blank" rel="noreferrer">[here]</a>
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="AMC 2023"
						description={
							<Typography.Text>
								Bronze award
							</Typography.Text>
						}
						org="Australian Mathematics Competition"
						tags={
							<>
							<Tag color="yellow">Competition</Tag>
							<Tag color="magenta">Examination</Tag>
							</>
						}
						link={"https://drive.google.com/open?id=1VH90dSXLr3aVhH6mhvA9Lg1uN_ASY1Zp"}
					>
						<Typography.Text>
							การแข่งขันคณิตศาสตร์ออสเตรเลียปี ค.ศ. 2023 ได้รับรางวัลระดับเหรียญทองแดง
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="DRU programming"
						description={
							<Typography.Text>
								รางวัลชนะเลิศ (อันดับ 1)
							</Typography.Text>
						}
						org="คณะวิทยาศาสตร์และเทคโนโลยี มหาวิทยาลัยราชภัฏธนบุรี"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="purple">Coding</Tag>
							</>
						}
						link={"https://facebook.com/jern.anuworakarn/posts/10224398593097697"}
					>
						<Typography.Text>
							การแข่งขัน "การเขียนโปรแกรมคอมพิวเตอร์พื้นฐาน" โครงการแข่งขันเขียนโปรแกรมคอมพิวเตอร์ระดับมัธยมศึกษาตอนปลายหรือเทียบเท่า ประจำปีการศึกษา 2566
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="1st Computing Fair"
						description={
							<Typography.Text>
								รางวัลชนะเลิศ (อันดับ 1)
							</Typography.Text>
						}
						org="วิทยาลัยการคอมพิวเตอร์ มหาวิทยาลัยขอนแก่น"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="orange">Web application</Tag>
							</>
						}
						link={"https://cpfair.computing.kku.ac.th/course/view-course?course_id=8"}
					>
						<Typography.Text>
							Web Programming Competition 2023 | The 1<sup>st</sup> Computing Fair 2023
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="DRU web application"
						description={
							<Typography.Text>
								รางวัลชนะเลิศ (อันดับ 1)
							</Typography.Text>
						}
						org="คณะวิทยาศาสตร์และเทคโนโลยี มหาวิทยาลัยราชภัฏธนบุรี"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="orange">Web application</Tag>
							</>
						}
					>
						<Typography.Text>
							การแข่งขัน "เขียนโปรแกรมคอมพิวเตอร์สำหรับพัฒนาเว็บแอปพลิเคชัน" โครงการแข่งขันเขียนโปรแกรมคอมพิวเตอร์ระดับมัธยมศึกษาตอนปลายหรือเทียบเท่า ประจำปีการศึกษา 2566
						</Typography.Text>
					</PortDrawer>
					<PortDrawer
						title="CTF 2023"
						description={
							<Typography.Text>
								Thailand Cyber Top Talent
							</Typography.Text>
						}
						org="สำนักงานคณะกรรมการการรักษาความมั่นคงปลอดภัยไซเบอร์แห่งชาติ สกมช. (NCSA)"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="purple">Computing</Tag>
							</>
						}
						link={"https://ctf.in.th/challenge/2254/"}
					>
						<Typography.Text>
							การแข่งขันด้านความมั่นคงปลอดภัยไซเบอร์ของประเทศไทย<br />Individual rank: 32 / 788<br />Team rank: 23 / 273
						</Typography.Text>
					</PortDrawer>
					{/* <PortDrawer
						title="KU01 - 2023"
						description={
							<Typography.Text>
								Algorithm problem solving challenge
							</Typography.Text>
						}
						org="Department of Computer Engineering Kasetsart University"
						tags={
							<>
								<Tag color="yellow">Competition</Tag>
								<Tag color="purple">Coding</Tag>
							</>
						}
						link={"https://ku01.org"}
					>
						<Typography.Text>
							ผ่านการทดสอบความสามารถในการแก้ปัญหาโดยการเขียนโปรแกรม โดยคณะวิศวกรรมศาสตร์ มหาลัยเกษตรศาสตร์ (<a href="https://ku01.org" target="_blank" rel="noreferrer">KU01</a>)
						</Typography.Text>
					</PortDrawer> */}
				</Space>
				{/* <Typography.Title level={4} style={{ color: "cyan", marginTop: 24 }}>Academic year 2024</Typography.Title>
				<Typography.Title level={5} type="warning">Coming Soon</Typography.Title>
				<Space size={16} wrap style={{ justifyContent: "center" }}>
					<PortDrawer
						title="___"
						description={
							<Typography.Text>
								___
							</Typography.Text>
						}
						org="___"
						tags={
							<>
								<Tag color="___">___</Tag>
							</>
						}
						link={"___"}
					>
						<Typography.Text>
							___
						</Typography.Text>
					</PortDrawer>
				</Space> */}
			</Col>
		</Row>
	);
}
