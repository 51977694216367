import {
  DownloadOutlined,
  TwitterOutlined,
  FacebookOutlined,
  InstagramOutlined,
  GithubOutlined,
} from "@ant-design/icons";
import { Button, Space, Typography } from "antd";
import Typewriter from "typewriter-effect";
import "./App.less";

export default function About(props: any) {
  return (
    <div
      style={{ position: "relative", minHeight: "100vh", maxHeight: "100vh" }}
      {...props}
    >
      <div
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
          margin: "auto",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography.Title>Chainath Limchunhanukul</Typography.Title>
        <Space style={{ marginBottom: 18 }} size={16}>
          <TwitterOutlined
            style={{ fontSize: "150%" }}
            onClick={() => window.open(`https://twitter.com/TianTcl`)}
          />
          <FacebookOutlined
            style={{ fontSize: "150%" }}
            onClick={() => window.open(`https://fb.me/TianTcl.net`)}
          />
          <InstagramOutlined
            style={{ fontSize: "150%" }}
            onClick={() => window.open(`https://instagr.am/TianTcl`)}
          />
          <GithubOutlined
            style={{ fontSize: "150%" }}
            onClick={() => window.open(`https://git.io/TianTcl`)}
          />
        </Space>
        <Typography.Title level={4}>
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .changeDelay(60)
                .changeDeleteSpeed(20)
                .typeString(`It's me, a <span style="color:red;">Software Engineer</span>`)
                .pauseFor(500)
                .deleteChars(17)
                .typeString(`<span style="color:orange;">DevOps</span>`)
                .pauseFor(700)
                .deleteChars(6)
                .typeString(`<span style="color:green;">Inovator</span>`)
                .pauseFor(700)
                .deleteChars(12)
                .changeDelay(40)
                .typeString(`<span style="color:aqua;"> Webapp producer</span>`)
                .changeDelay(60)
                .pauseFor(500)
                .deleteAll()
                .typeString(`I am `)
                .changeDelay(200)
                .typeString(`<span style="color: #177ddc ;">Tecillium (UFDT)</span>`)
                .pauseFor(300)
                .typeString(`.`)
                .pauseFor(3500)
                .start();
            }}
            options={{ autoStart: true, loop: true, cursor: "_" }}
          />
        </Typography.Title>
        <Button type="primary" download href="/v2/Chainath Limchunhanukul.pdf" icon={<DownloadOutlined/>}>Download CV</Button>
      </div>
    </div>
  );
}
