/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Modal, Card, Typography, Row, Col, Divider, Button, Tag } from "antd";

export default function PortItem({
  title,
  children,
  tags,
  description,
  org,
  link,
  status,
}: any) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancelOverwrite = () => {
    setIsModalVisible(false);
  };


  let statusColor = "";
  switch (status) {
    case "Finished":
      statusColor = "blue";
      break;
    case "In Progress":
      statusColor = "green";
      break;
    default:
      statusColor = "";
      break;
  }

  return (
    <>
      <Card
        title={title}
        style={{ maxWidth: 270, minHeight: 150 }}
        hoverable
        onClick={showModal}
      >
        {description}
      </Card>
      <Modal
        footer={[
          <Button type="ghost" onClick={handleCancelOverwrite}>
            Back
          </Button>,
          link ? (
            <Button onClick={() => window.open(link)} type="primary">
              Take a look at this
            </Button>
          ) : null,
        ]}
        title={
          <Row align="middle">
            <Col>
              <Typography.Title level={2} style={{ margin: "auto" }}>
                {title}
              </Typography.Title>
            </Col>
            {status ? (
              <Col>
                <Tag style={{ margin: "0 12px" }} color={statusColor}>
                  {status}
                </Tag>
              </Col>
            ) : null}
          </Row>
        }
        visible={isModalVisible}
      >
        {description}
        {org ? (
          <Row style={{ paddingTop: 14 }}>
            <Col span={6}>
              <b>Organization</b>
            </Col>
            <Col span={18}>{org}</Col>
          </Row>
        ) : null}
        <Row style={{ padding: 14 }}>{tags}</Row>
        <Divider orientation="left">About</Divider>
        {children}
      </Modal>
    </>
  );
}
