import { Typography, Space, Col, Row, Tag } from "antd";
import "./App.less";
import PortDrawer from "./assets/drawer";

export default function Projects(props: any) {
  return (
    <Row style={{ width: "100%", minHeight: "100vh" }} align="middle">
      <Col span={24}>
        <Typography.Title style={{ marginTop: 24 }}>Projects</Typography.Title>
        <Typography.Title level={5} style={{ marginTop: -15 }} type="warning">Click to see more..</Typography.Title>
        <Typography.Title level={4} style={{ color: "cyan", marginTop: 12 }}>My projects</Typography.Title>
        <Space size={16} wrap style={{ justifyContent: "center" }}>
          <PortDrawer
            title="Onet-Online"
            description={
              <Typography.Text>
                ระบบคลังแบบทดสอบอัจฉริยะออนไลน์
              </Typography.Text>
            }
            tags={
              <>
                <Tag color="orange">Web application</Tag>
              </>
            }
            link={"https://onet.TianTcl.net"}
            status="Finished"
          >
            <Typography.Text>
              ระบบช่วยประเมินผลตนเองและชี้นำแนวทางการพัฒนาความรู้ด้านวิทยาศาสตร์ คณิตศาสตร์ ภาษาไทย และภาษาอังกฤษ
            </Typography.Text>
          </PortDrawer>
          <PortDrawer
            title="TheMatrix"
            description={
              <Typography.Text>
                โปรแกรมอำนวยความสะสวกทางเมทริกซ์
              </Typography.Text>
            }
            tags={
              <>
                <Tag color="black">Console program</Tag>
                <Tag color="green">Python</Tag>
              </>
            }
            link={"https://old.TianTcl.net/v1/ref/TheMatrix"}
            status="Finished"
          >
            <Typography.Text>
              Smart matrix operation calculator
            </Typography.Text>
          </PortDrawer>
          <PortDrawer
            title="SortBox"
            description={
              <Typography.Text>
                Application for warehouse optimization (Logistics)
              </Typography.Text>
            }
            tags={
              <>
                <Tag color="black">Console program</Tag>
                <Tag color="green">Python</Tag>
                <Tag color="orange">Web application</Tag>
                <Tag color="yellow">Javascript</Tag>
              </>
            }
            link={"https://old.TianTcl.net/v1/ref/SortBox"}
            status="Finished"
          >
            <Typography.Text>
              แอปพลิเคชันเพิ่มประสิทธิภาพการจัดเรียงกล่องบรรจุภัณฑ์สำหรับงานโลจิสติกส
            </Typography.Text>
          </PortDrawer>
          <PortDrawer
            title="Bschool"
            description={
              <Typography.Text>
                Self interactive learning system
              </Typography.Text>
            }
            tags={
              <>
                <Tag color="orange">Web application</Tag>
              </>
            }
            link={"https://self.edu.TianTcl.net"}
            status="Finished"
          >
            <Typography.Text>
              ระบบจัดการเรียนการสอนออนไลน์
            </Typography.Text>
          </PortDrawer>
          <PortDrawer
            title="PBL manager"
            description={
              <Typography.Text>
                Project Management System
              </Typography.Text>
            }
            tags={
              <>
                <Tag color="orange">Web application</Tag>
                <Tag color="yellow">Javascript</Tag>
                <Tag color="violet">PHP</Tag>
                <Tag color="orange">MySQL</Tag>
              </>
            }
            link={"https://inf.bodin.ac.th/s/PBL/v2/"}
            status="In Progress"
          >
            <Typography.Text>
              ระบบบริหารจัดการโครงงาน
              <br /><br />
              ลิงก์แนะนำ<i>การใช้งาน</i>ระบบ
              <ul>
                <li>นักเรียน&emsp;<a href="https://drive.google.com/a/bodin.ac.th/open?id=1-CUAnQJUDdowRi0hAoq5v9kZwZIzQrx3" target="_blank" rel="noreferrer">คู่มือ</a>&emsp;<a style={{display: "none"}} href="" target="_blank" rel="noreferrer">วิดีโอ</a></li>
                <li>ครูผู้สอน&emsp;<a href="https://drive.google.com/a/bodin.ac.th/open?id=1mcywgRs5kXcTYgbWYPmXMMIWyhyldJLT" target="_blank" rel="noreferrer">คู่มือ</a>&emsp;<a style={{display: "none"}} href="" target="_blank" rel="noreferrer">วิดีโอ</a></li>
              </ul>
            </Typography.Text>
          </PortDrawer>
          <PortDrawer
            title="B.D.F.S"
            description={
              <Typography.Text>
                Barter-Deposit-Found-Share (ฝาก-แลก-แจก-คืน)
              </Typography.Text>
            }
            tags={
              <>
                <Tag color="orange">Web application</Tag>
                <Tag color="lime">Community</Tag>
              </>
            }
            link={"https://inf.bodin.ac.th/project/BDFS/"}
            status="In Progress"
          >
            <Typography.Text>
              โครงการเพื่อประสานผู้คนในชุมชน สร้างความสัมพันธ์ การติดต่อซื้อขายแลกเปลี่ยน ลดปริมาณขยะ
            </Typography.Text>
          </PortDrawer>
        </Space>
        <Typography.Title level={4} style={{ color: "cyan", marginTop: 12 }}>My experiences</Typography.Title>
        <Space size={16} wrap style={{ justifyContent: "center" }}>
          <PortDrawer
            title="Admission System"
            description={
              <Typography.Text>
                ระบบยืนยันสิทธิ์เข้าศึกษาต่อ / เปลี่ยนกลุ่มการเรียน
              </Typography.Text>
            }
            tags={
              <>
                <Tag color="orange">Web application</Tag>
                <Tag color="yellow">Javascript</Tag>
                <Tag color="violet">PHP</Tag>
                <Tag color="orange">MySQL</Tag>
              </>
            }
            link={"https://inf.bodin.ac.th/e/enroll/"}
            status="Finished"
          >
            <Typography.Text>
              ช่วยอำนวยความสะดวกในการยืนยันสิทธิ์
              <ul>
                <li>ไม่ต้องเสียเวลาเดินทาง</li>
                <li>ทราบจำนวนที่นั่งว่างทันที</li>
              </ul>
            </Typography.Text>
          </PortDrawer>
          <PortDrawer
            title="Bodin API"
            description={
              <Typography.Text>
                School's Rest API
              </Typography.Text>
            }
            tags={
              <>
                <Tag color="violet">PHP</Tag>
                <Tag color="orange">MySQL</Tag>
              </>
            }
            link={"https://sapi.bodin.ac.th"}
            status="Finished"
          >
            <Typography.Text>
              Connect Bodindecha (Sing Singhaseni) School Services
            </Typography.Text>
          </PortDrawer>
          <PortDrawer
            title="URL Shortener"
            description={
              <Typography.Text>
                ระบบย่อลิงก์
              </Typography.Text>
            }
            tags={
              <>
                <Tag color="orange">Web application</Tag>
                <Tag color="yellow">Javascript</Tag>
                <Tag color="violet">PHP</Tag>
                <Tag color="orange">MySQL</Tag>
              </>
            }
            link={"https://bod.in.th"}
            status="Finished"
          >
            <Typography.Text>
              สำหรับบุคคลากรโรงเรียนบดินทรเดชา (สิงห์ สิงหเสนี) ไว้ย่อลิงก์ให้สั้นลงเพื่อให้ง่ายต่อการจำและประหยัดความยาวข้อความเวลาส่งต่อหรือแชร์
            </Typography.Text>
          </PortDrawer>
          <PortDrawer
            title="Bodin Information System"
            description={
              <Typography.Text>
                ระบบสารสนเทศ โรงเรียนบดินทรเดชา (สิงห์ สิงหเสนี)
              </Typography.Text>
            }
            tags={
              <>
                <Tag color="orange">Web application</Tag>
                <Tag color="yellow">Javascript</Tag>
                <Tag color="violet">PHP</Tag>
                <Tag color="orange">MySQL</Tag>
              </>
            }
            link={"https://inf.bodin.ac.th"}
            status="Finished"
          >
            <Typography.Text>
              ช่วยอำนวยความสะดวกต่างๆ ให้แก่ ครู นักเรียน และบุคคลากรโรงเรีบนบดินทรเดชา (สิงห์ สิงหเสนี)
            </Typography.Text>
          </PortDrawer>
        </Space>
        <Typography.Title level={4} style={{ color: "cyan", marginTop: 24 }}>Cooperated projects</Typography.Title>
        <Space size={16} wrap style={{ justifyContent: "center" }}>
          <PortDrawer
            title="CSA3D"
            description={
              <Typography.Text>
                Anchor-Free LiDAR for 3d Object detection (AFLiDAR)
              </Typography.Text>
            }
            tags={
              <>
                <Tag color="blue">AI</Tag>
                <Tag color="green">Python</Tag>
              </>
            }
            link={"https://github.com/PatrickChoDev/AFLiDAR"}
            status="Finished"
          >
            <Typography.Text>
              By: Thanapat Chotipun (<a href="https://github.com/PatrickChoDev" target="_blank" rel="noreferrer">Patrick</a>)
            </Typography.Text>
          </PortDrawer>
          <PortDrawer
            title="เรือเก็บขยะ"
            description={
              <Typography.Text>
                เรือเก็บขยะบนผิวน้ำอัตโนมัติ
              </Typography.Text>
            }
            tags={
              <>
                <Tag color="cyan">Environment</Tag>
              </>
            }
            status="Finished"
          >
            <Typography.Text>
              By: Natalie Malamas (Nice)
            </Typography.Text>
          </PortDrawer>
          <PortDrawer
            title="Pathway Speech Contest"
            description={
              <Typography.Text>
                Festival Speech Submit
              </Typography.Text>
            }
            tags={
              <>
                <Tag color="lime">Community</Tag>
                <Tag color="orange">Web application</Tag>
              </>
            }
            link={"https://PathwaySpeechContest.cf"}
            status="In Progress"
          >
            <Typography.Text>
              Co-founder &amp; System developer of organization (run by talented students from over 6 schools).<hr />By: Chinnicha Boonyoo (Nicha)
            </Typography.Text>
          </PortDrawer>
          <PortDrawer
            title="Clothe to Share"
            description={
              <Typography.Text>
                โครงการส่งต่อเครื่องแบบนักเรียน
              </Typography.Text>
            }
            tags={
              <>
                <Tag color="lime">Community</Tag>
              </>
            }
            link={"https://instagram.com/clothe_to_share"}
            status="In Progress"
          >
            <Typography.Text>
              สำหรับให้นักเรียนที่จบการศึกษาจากโรงเรียนบดินทรเดชา (สิงห์ สิงหเสนี) แล้ว ได้นำชุดเครื่องแบบต่างๆ มาส่งต่อให้กับนักเรียนใหม่ ที่มีความต้องการ<hr />By: Darakarn Limkool (Teety)
            </Typography.Text>
          </PortDrawer>
          <PortDrawer
            title="ธนาคารขยะรีไซเคิล"
            description={
              <Typography.Text>
                ระบบรับฝากขยะรีไซเคิล สะสมและแลกเปลี่ยนเป็นเครดิต
              </Typography.Text>
            }
            tags={
              <>
                <Tag color="orange">Web application</Tag>
                <Tag color="cyan">Environment</Tag>
              </>
            }
            link={"https://inf.bodin.ac.th/project/BDFS/"}
            status="Finished"
          >
            <Typography.Text>
              โครงการโรงเรียนปลอดขยะ<hr />นายณัฐพงศ์ มนต์อ่อน - โรงเรียนบดินทรเดชา (สิงห์ สิงหเสนี)
            </Typography.Text>
          </PortDrawer>
        </Space>
      </Col>
    </Row>
  );
}
