import { CheckOutlined, ExclamationOutlined } from "@ant-design/icons";
import { Typography, Space, Col, Row, Form, Input, Select, Button } from "antd";
import { send, init } from "emailjs-com";
import { useState } from "react";
import "./App.less";

init("Q5q0DmSpJhoq-GfSi");

const { Option } = Select;

export default function Contact(props: any) {
  const [sending, setSend] = useState(false);
  const [icon, setIcon] = useState(<></>);
  const [sendText, setText] = useState("Send TianTcl a mail");
  const [status, setStatus] = useState(false);
  const sendMail = (v: any) => {
    setSend(true);
    setText("Sending");
    setIcon(<></>);
    setStatus(false);
    let mailOptions = {
      reply_to: v.mail,
      category: v.heading.category,
      topic: v.heading.topic,
      message: v.message,
    };
    send("service_o52x7ax", "template_97id97x", mailOptions)
      .then((res) => {
        setSend(false);
        setText("The mail was sent!");
        setIcon(<CheckOutlined />);
      })
      .catch((err) => {
        setSend(false);
        setText("Error");
        setIcon(<ExclamationOutlined />);
        setStatus(true);
      });
  };
  return (
    <Row style={{ width: "100%", minHeight: "100vh" }} align="middle">
      <Col span={24}>
        <Typography.Title style={{ marginTop: 24 }}>Contact</Typography.Title>
        <Typography.Title level={5} type="warning"></Typography.Title>
        <Row justify="center" style={{ paddingTop: 24 }}>
          <Form
            name="complex-form"
            onFinish={sendMail}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            style={{ width: "60%" }}
          >
            <Form.Item label="From">
              <Form.Item
                name="mail"
                noStyle
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Where should I send you an reply email",
                  },
                ]}
              >
                <Input style={{ width: "100%" }} placeholder="E-Mail Address" />
              </Form.Item>
            </Form.Item>
            <Form.Item label="Subject">
              <Input.Group>
                <Form.Item
                  name={["heading", "category"]}
                  noStyle
                  rules={[
                    { required: true, message: "Please provide me a category" },
                  ]}
                >
                  <Select placeholder="Cetegory" style={{ width: "40%" }}>
                    <Option value="Invite">Invitation</Option>
                    <Option value="Hiring">Hiring</Option>
                    <Option value="Colab">Collaborate</Option>
                    <Option value="Teach">Tutoring</Option>
                    <Option value="General">Other</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name={["heading", "topic"]}
                  noStyle
                  rules={[{ required: true, message: "Topic is required" }]}
                >
                  <Input style={{ width: "60%" }} placeholder="Topic" />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item
              name="message"
              label="Message"
              style={{ marginBottom: 20 }}
            >
              <Input.TextArea rows={10} placeholder="Message" />
            </Form.Item>

            <Typography.Text code>
              NOTE : If you want to send a file please attach as URL to download
            </Typography.Text>
            <br />
            <Typography.Text code>
              Temporary file? I reccomend{" "}
              <a target="_blank" rel="noreferrer" href="https://tmp.ninja">
                tmp.ninja
              </a>
            </Typography.Text>
            <br />
            <Space>
              <Button
                type="primary"
                icon={icon}
                htmlType="submit"
                loading={sending}
                danger={status}
              >
                {sendText}
              </Button>

              <Button type="default" htmlType="reset">
                Clear
              </Button>
            </Space>
          </Form>
        </Row>
      </Col>
    </Row>
  );
}
